<template>
    <div class="home">
      <Home />
    </div>
    <div class="latest">
        <Latest />
    </div>
    
  </template>
  
  <script>
  // @ is an alias to /src
  import Home from '@/components/Home.vue'
  import Latest from '@/components/Latest.vue'
  
  export default {
    name: 'HomeView',
    components: {
      Home,
      Latest
    }
  }
  </script>
  