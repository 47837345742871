


<template>
  <div class="camera-wrapper">
    <div class="flip-button" @click="toggleCamera"><svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10" y="25" width="80" height="50" rx="10" fill="black"/>
    <circle cx="50" cy="50" r="15" fill="white"/>
    <rect x="35" y="40" width="30" height="20" fill="black"/>
    <circle cx="50" cy="50" r="10" fill="lightgray"/>
    <rect x="20" y="20" width="15" height="10" rx="2" fill="silver"/>
    <rect x="65" y="20" width="15" height="10" rx="2" fill="silver"/>
</svg></div>
    <div class="camera" :class="{ flip: facingMode === 'user' }">
      <qrcode-stream @decode="onDecode" @init="onInit" :constraints="cameraConstraints"></qrcode-stream>
    </div>
    <Hasil v-if="decodedContent" :content="decodedContent" @close="closeModal" @goHome="goHome" />
  </div>
</template>

<script>
import { QrcodeStream } from 'vue3-qrcode-reader';
import Hasil from './Hasil.vue';

export default {
  components: {
    QrcodeStream,
    Hasil,
  },
  data() {
    return {
      decodedContent: '',
      facingMode: 'user', // Initial camera facing mode
    };
  },
  computed: {
    cameraConstraints() {
      //return { facingMode: this.facingMode };
      return { facingMode: { exact: this.facingMode } };
    },
  },
  methods: {
    onDecode(content) {
      this.decodedContent = content;
    },
    onInit(promise) {
      promise.then(() => {
        console.log('QR Code scanner is ready!');
      }).catch(error => {
        console.error('Failed to initialize QR Code scanner:', error);
      });
    },
    closeModal() {
      this.$router.push('/'); // Navigate to Home route
      this.decodedContent = ''; // Resets the content, hiding the modal
    },
    goHome() {
      this.$router.push('/'); // Navigate to Home route
    },
    toggleCamera() {
      this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
    },
  },
};
</script>

<style scoped>
.camera-wrapper {
  position: relative;
}

.flip-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10; /* Ensure the button is above the camera view */
}

.camera {
  /* Adjustments for camera styling if necessary */
}

.flip {
  transform: scaleX(-1);
}

button{
    width: 150px;
    height: 70px;
    padding: 10px;
    margin: 4px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 20px; 
    background-color: green;
    color: white;
  }
</style>
