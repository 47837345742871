// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*const firebaseConfig = {
  apiKey: "AIzaSyDLQnkoKZxnYIgKoanrypvl8K3M0qsDo9A",
  authDomain: "bukutamusf.firebaseapp.com",
  databaseURL: "https://bukutamusf-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "bukutamusf",
  storageBucket: "bukutamusf.appspot.com",
  messagingSenderId: "845375622531",
  appId: "1:845375622531:web:22422057f2770a19bffab6",
  measurementId: "G-28BL6SH785"
};
*/
const firebaseConfig = {
  apiKey: "AIzaSyAG4z7XzZ_FV_VrD77NxfGN5rnPRRlkqBE",
  authDomain: "bukutamu-anitafauzan.firebaseapp.com",
  projectId: "bukutamu-anitafauzan",
  storageBucket: "bukutamu-anitafauzan.appspot.com",
  messagingSenderId: "598293450371",
  appId: "1:598293450371:web:867c58cd1948a41588d5b6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };