<template>
    <div class="undangan-list">
      <ul>
        <li v-for="undangan in undangans" :key="undangan.id">
          <div class="content">
            <h3>{{ undangan.name }}</h3>
            <p>({{ undangan.relation }})</p>
            <p>{{ undangan.attend_time }}</p>
          </div>
        </li>
      </ul>
    </div>
  </template>
  

<script setup>
import { onMounted, ref } from 'vue';
import { db } from '@/firebaseConfig';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';

const undangans = ref([]);

function formatDate(date) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let day = date.getDate();
  let month = months[date.getMonth()];
  let year = date.getFullYear().toString().substr(-2);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

onMounted(async () => {
  const q = query(collection(db, "undangan"), orderBy("attend_time", "desc"), limit(12));
  const querySnapshot = await getDocs(q);
  undangans.value = querySnapshot.docs.map(doc => {
    const data = doc.data();
    data.attend_time = formatDate(data.attend_time.toDate()); // Format the date
    return { id: doc.id, ...data };
  });
});
</script>


<style scoped>

.undangan-list {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column; /* Align children in a column */
  align-items: center; /* Center items horizontally */
  padding: 20px;
  gap: 20px;
  background-color: #B6D3E6; /* Dark background for the whole list */
}

ul {
  list-style-type: none;
  padding: 0;
  width: 100%; /* Full width of its container */
}

li {
  background-color: #133c56; /* Darker shade for items */
  color: #CCCCCC; /* Lighter text for readability */
  width: 100%; /* Take the full width to form a row */
  margin: 10px 0; /* Margin top and bottom for spacing between rows */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.5); /* Darker shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

li:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.7); /* Enhanced shadow on hover for better effect */
}

.content {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Keep content vertically aligned */
  justify-content: space-around;
  width: 90%; /* Adjust based on your design preference */
}

h3, p {
  color: #FFFFFF; /* Adjust colors as needed */
  margin-bottom: 5px;
}

p {
  color: #BBBBBB; /* Slightly dimmer text for paragraphs */
  font-size: 0.9em;
}

</style>