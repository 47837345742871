<template>
  <div class="home">
    
    <Pindai/>
  </div>
</template>

<script>
// @ is an alias to /src
import Pindai from '@/components/Pindai.vue'

export default {
  name: 'PindaiView',
  components: {
    Pindai
  }
}
</script>
