<template>
    <div class="home">
      
      
        <router-link to="/pindai" class="option" id="pindai">
          <i class="fas fa-qrcode"></i>
          <br/>
          <span>Pindai Kode QR</span>
        </router-link>
      
        <router-link to="/manual" class="option" id="manual">
          <i class="fas fa-keyboard"></i>
          <br/>
          <span>Ketik Manual</span>
        </router-link>
      
    </div>
  </template>
<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
}


.option {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  max-width: 300px;
  height: 90%;
  max-height: 300px;
  margin: 20px;
  padding: 20px;
  background-color: #133c56;
  color: white;
  border-radius: 10px;
  transition: transform 0.2s;
  text-decoration: none; /* Remove underline from links */
  font-size: 40px;

}

.option:hover {
  transform: scale(1.1);
}

.option i {
  margin-bottom: 10px;
  font-size: 24px;
}

@media (min-width: 600px) {
  .home {
    flex-direction: row;
  }
}
</style>
