import { createRouter, createWebHistory } from 'vue-router'
import PindaiView from '../views/PindaiView.vue'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pindai',
    name: 'pindai',
    component: PindaiView
  },
  {
    path: '/manual',
    name: 'manual',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManualView.vue')
  },
  {
    path: '/hasil',
    name: 'hasil',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HasilView.vue'),
    props: true
  },
  {
    path: '/manualhasil',
    name: 'Manualhasil',
    component: () => import(/* webpackChunkName: "about" */ '../views/ManualhasilView.vue'),
    props: true // This allows passing props via route parameters
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
